import { screenApi } from 'apis'

export async function getUser(id, query) {
  const { data } = await screenApi.get(`/users/${id}`, { params: query })
  return data.user
}

export async function getUserPaymentMethods() {
  const { data } = await screenApi.get('/users/profile/payment_methods')
  return data.payment_methods
}

export async function deleteUserPaymentMethod(id, query) {
  return await screenApi.delete(`/users/profile/payment_methods/${id}`)
}
