import { useQuery } from '@tanstack/react-query'
import { getUser } from 'api/user'
import { STALE_TIME, serializeQueryKey } from 'helpers/query'
import { useCallback } from 'react'
import { create, emptyUser } from 'schemas/user'

export function useUserQuery(id, query, { select: transformData, ...reactQueryOptions }) {
  return useQuery({
    placeholderData: emptyUser,
    staleTime: STALE_TIME,
    queryKey: serializeQueryKey('user', {
      id,
      ...query,
    }),
    queryFn: () => getUser(id, query),
    select: useCallback(
      (raw) => {
        const data = create(raw)
        return transformData ? transformData(data) : data
      },
      [transformData],
    ),
    ...reactQueryOptions,
  })
}
