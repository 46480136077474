import React from 'react'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'
import IconScreen from '@vizeat/components/es6/assets/icons/IconScreen'
import { Indicator, indicatorPropTypes, indicatorDefaultProps } from './Indicator'
import PropTypes from 'helpers/proptypes'

export function OnlineExperienceIndicator({
  tooltipStyles,
  tooltipContentStyles,
  whiteSpace,
  isCompact,
  size,
  tooltipPosition,
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Indicator
      isCompact={isCompact}
      icon={<IconScreen size={size} />}
      tooltipStyles={tooltipStyles}
      tooltipContentStyles={tooltipContentStyles}
      color='white'
      background={`linear-gradient(90deg, ${theme.colors.primary} 0%, ${theme.colors.pink} 100%)`}
      whiteSpace={whiteSpace}
      content={t('Badge::Eatwith live')}
      tooltipContent={t('Badge::Eatwith live')}
      tooltipPosition={tooltipPosition}
    />
  )
}

OnlineExperienceIndicator.propTypes = {
  ...indicatorPropTypes,
  size: PropTypes.string,
}

OnlineExperienceIndicator.defaultProps = {
  ...indicatorDefaultProps,
  size: undefined,
}
