import { buildSearchQueryKey, isMapSearch, SEARCH_RESULT_SIZE } from 'helpers/search'
import { fromJS, List, Map } from 'immutable'
import moment from 'moment'
import { useCallback } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { EventRecord, PlaceRecord, UserRecord, FileRecord, emptyPlace } from 'redux/schemas'
import { fetchSearch } from 'api/search'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { usePlaylist } from './usePlaylist'
import { STALE_TIME } from 'helpers/query'
import useDebouncedState from 'hooks/useDebouncedState'
import { getBounds } from 'helpers/places'

const RADIUS_FALLBACK = 50_000

function calculateNextDate({ from, to, eventDates }) {
  const tomorrow = moment.utc().startOf('day').add(1, 'day')

  // compute start date
  const startRange = from ? moment.utc(from) : undefined
  let startEventDate = moment.utc(eventDates.start)
  if (startRange && startEventDate.isBefore(startRange)) startEventDate = startRange

  // compute end date
  const endRange = to ? moment.utc(to) : undefined
  let endEventDate = moment.utc(eventDates.end)
  if (endRange && endEventDate.isAfter(endRange)) endEventDate = endRange

  if (startEventDate.isAfter(tomorrow)) return startEventDate
  if (endEventDate.isBefore(tomorrow)) return endEventDate
  return tomorrow
}

function toEvent(
  {
    range,
    certification_items: certificationItems,
    cover_url: coverURL,
    user: { avatar_url: avatarURL, ...user },
    place,
    ...searchEvent
  },
  { from, to },
) {
  return new EventRecord({
    ...searchEvent,
    place: new PlaceRecord(fromJS(place)),
    user: new UserRecord(
      fromJS({
        ...user,
        avatar: new FileRecord({
          id: user.avatar_id,
          path: avatarURL,
        }),
      }),
    ),
    cover: new FileRecord({
      id: searchEvent.cover_id,
      path: coverURL,
    }),
    next_date: Map({
      date: calculateNextDate({ from, to, eventDates: { start: range.start, end: range.end } }),
      available_seats: range.available_seats ?? undefined,
      booked_seats: range.booked_seats ?? undefined,
      range_status: range.status ?? undefined,
    }),
  })
}

const emptySearchResult = {
  events: List(),
  place: emptyPlace,
  viewport: Map({}),
  facets: Map({}),
  totalCount: 0,
}

function useSearchParamsFromPlaylist(playlistId, { enabled }) {
  const { data, isFetching } = usePlaylist(playlistId, { enabled })
  return { playlistPayload: data?.search, isPlaylistFetching: isFetching }
}

export function useSearchQuery(
  { playlist, ...searchPayload },
  {
    forcedCurrencyIso3,
    allowPublicBookings,
    canUseBounds = true,
    enabled = true,
    debounceDelay = 0,
    ...reactQueryOptions
  } = {},
) {
  const { appCurrencyIso3, getCurrencyByIso3 } = useCurrency()
  const initialSize = searchPayload.size ?? SEARCH_RESULT_SIZE
  const { playlistPayload, isPlaylistFetching } = useSearchParamsFromPlaylist(playlist, {
    enabled: enabled && !!playlist,
  })

  function isEnabled() {
    if (!enabled) return false
    if (playlist) return !isPlaylistFetching
    return true
  }

  const payload = {
    ...searchPayload,
    ...playlistPayload,
  }

  const debouncedQueryKey = useDebouncedState(
    buildSearchQueryKey(payload, forcedCurrencyIso3 || appCurrencyIso3),
    debounceDelay,
  )

  const searchQueryResult = useInfiniteQuery({
    staleTime: STALE_TIME,
    queryKey: debouncedQueryKey,
    queryFn: (params) => {
      return fetchSearch(
        {
          ...payload,
          currency: forcedCurrencyIso3 ? getCurrencyByIso3(forcedCurrencyIso3) : undefined,
          size: params?.pageParam?.size ?? initialSize,
          offset: params?.pageParam?.offset ?? 0,
        },
        { allowPublicBookings, canUseBounds },
      )
    },
    getNextPageParam: (lastPage) => {
      const size = parseInt(lastPage.size, 10)
      const offset = parseInt(lastPage.offset, 10)
      const total = parseInt(lastPage.total, 10)
      if (!lastPage.events || offset + lastPage.events.length >= total) return
      return {
        size,
        offset: offset + initialSize,
      }
    },
    select: useCallback(
      ({ pages }) => {
        const { place, viewport, facets, total } = pages[pages.length - 1]

        const data = {
          events: pages.reduce((acc, page) => {
            const eventRecords = page.events.map((event) => toEvent(event, { from: payload.start, to: payload.end }))
            return acc.concat(eventRecords)
          }, List()),
          place: new PlaceRecord(
            fromJS({
              ...place,
              coordinates: {
                // handle map vs place search
                geometry: place?.geometry,
                ...place?.coordinates,
              },
            }),
          ),
          viewport: Map(viewport),
          facets: fromJS(facets),
          totalCount: total,
        }

        return data
      },
      [payload.start, payload.end],
    ),
    enabled: isEnabled(),
    ...reactQueryOptions,
  })

  function selectData(selector) {
    return selector(searchQueryResult.isSuccess ? searchQueryResult.data : emptySearchResult)
  }

  return {
    searchQueryResult,
    selectData,
  }
}

export function useSearchQueryWithFallback(payload, options) {
  const initialQuery = useSearchQuery(payload, options)
  const { events } = initialQuery.selectData((data) => data)

  const shouldIncreaseRadius =
    !isMapSearch(payload) &&
    !!payload.q &&
    initialQuery.searchQueryResult.isSuccess &&
    !initialQuery.searchQueryResult.isPreviousData &&
    events.size === 0

  const fallbackQuery = useSearchQuery(
    {
      q: payload.q,
      pid: payload.pid,
      start: payload.start,
      end: payload.end,
      seats: payload.seats,
      size: payload.size,
      radius: RADIUS_FALLBACK,
    },
    {
      ...options,
      enabled: shouldIncreaseRadius,
    },
  )

  const { place: initialPlace, viewport: initialViewport } = initialQuery.selectData((data) => data)

  const {
    place: fallbackPlace,
    viewport: fallbackViewport,
    events: fallbackEvents,
  } = fallbackQuery.selectData((data) => data)

  // isPreviousData becomes true when the query key changes while the data remains the same
  // so we can use it to reset the place details when the query params change
  const placeDetails =
    fallbackQuery.searchQueryResult.isPreviousData || fallbackEvents.size === 0
      ? null
      : {
          locality: fallbackPlace.locality,
          lat: fallbackPlace.lat,
          lng: fallbackPlace.lng,
        }

  if (!shouldIncreaseRadius || fallbackEvents.size === 0) {
    return {
      placeDetails,
      hasResultsFromFallback: false,
      ...(!shouldIncreaseRadius ? initialQuery : fallbackQuery),
    }
  }

  const initialBounds = getBounds(initialPlace, initialViewport)
  const fallbackBounds = getBounds(fallbackPlace, fallbackViewport)

  const combinedBounds = {
    southwest: {
      lat: Math.min(initialBounds.southwest.lat, fallbackBounds.southwest.lat),
      lng: Math.min(initialBounds.southwest.lng, fallbackBounds.southwest.lng),
    },
    northeast: {
      lat: Math.max(initialBounds.northeast.lat, fallbackBounds.northeast.lat),
      lng: Math.max(initialBounds.northeast.lng, fallbackBounds.northeast.lng),
    },
  }

  return {
    combinedMapBounds: combinedBounds,
    hasResultsFromFallback: fallbackQuery.searchQueryResult.isSuccess,
    placeDetails,
    ...fallbackQuery,
  }
}
