import React, { memo, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useLocale } from 'hooks/locales/useLocale'
import { useTranslation } from 'next-i18next'
import moment from 'moment'
import { useIsRtl } from 'hooks/locales/useIsRtl'
import { getEventTitle } from 'helpers/legacy'
import { EventCard } from '@vizeat/components/es6/components/EventCard'
import { SmartLink } from 'components/shared/SmartLink'
import { ReviewRating } from 'components/shared/ratings/ReviewRating'
import { scaleCropFile } from 'helpers/ImageManager'
import { Badge } from '@vizeat/components/es6/components/Badge'
import IconMasterchef from '@vizeat/components/es6/assets/icons/IconMasterchef'
import {
  NewExperienceIndicator,
  StarChefIndicator,
  BestSellerIndicator,
  OnlineExperienceIndicator,
  EcoHeroIndicator,
} from 'components/shared/indicators'
import { gtmEventClicked } from 'gtm'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { usePriceFormatter } from 'hooks/price'
import { useRouter } from 'next/router'
import { DEFAULT_SEATS } from 'helpers/search'
import { useTranslatedMealtypes } from 'hooks/events/useTranslatedFilters'

export const BasicSearchEventCard = memo(function BasicSearchEventCard({
  event,
  eventUrl,
  hideSeatsLeft,
  hideNextAvailableDate,
  gtmType,
  showCarousel,
  place,
  isSelected,
  isHovered,
  height,
  variant,
  position,
}) {
  const { t } = useTranslation()
  const { direction } = useIsRtl()
  const { locale } = useLocale()
  const isSmall = variant === 'small'
  const translatedMealtype = useTranslatedMealtypes(event.type)

  const formattedPrice = usePriceFormatter({ price: event.price })
  const bookedSeats = event.next_date.get('booked_seats')
  const { user: host } = event
  const isTabletOrHigher = useMediaQuery('tablet')
  const eventPlace = place || event.place
  const { nbguests: nbguestsFromSearch } = useRouter().query

  const images = useMemo(() => {
    return [
      ...new Set([
        scaleCropFile({ fileId: event.cover_id, size: '350x350', smart: true }),
        ...event.files_ids.map((fileId) => scaleCropFile({ fileId, size: '350x350', smart: true })),
      ]),
    ]
  }, [event.cover_id, event.files_ids])

  const nextDate = useMemo(() => {
    const date = event.next_date.get('date')
    return date ? moment.utc(date).format('YYYY-MM-DD') : undefined
  }, [event.next_date])

  const eventPathDescriptor = useMemo(() => {
    if (eventUrl) return eventUrl
    return {
      pathname: `/events/${event.id}`,
      query: { date: nextDate, seats: nbguestsFromSearch || DEFAULT_SEATS },
    }
  }, [event.id, eventUrl, nbguestsFromSearch, nextDate])

  const seatsLeft = useMemo(() => {
    if (bookedSeats === 0) return null
    const availableSeats = event.next_date.get('available_seats')

    if (availableSeats <= 0) {
      return {
        seatsLeftText: t('EventCard::SOLD OUT'),
      }
    }

    const totalSeats = availableSeats + bookedSeats
    if (availableSeats <= Math.ceil(totalSeats * 0.4)) {
      return {
        seatsLeft: availableSeats,
        seatsLeftText: 'left',
      }
    }

    return null
  }, [bookedSeats, event.next_date, t])

  const eventIndicator = useMemo(() => {
    const tooltipPosition = 'bottom'
    if (host.is_eco_hero) return <EcoHeroIndicator tooltipPosition={tooltipPosition} />

    if (event.masterchef_us && typeof event.masterchef_us === 'number') {
      return (
        <Badge backgroundColor='white' color='primary'>
          <IconMasterchef color='primary' mr='4px' />
          {t('Masterchef::Season {{ seasonNumber }}', { seasonNumber: event.masterchef_us })}
        </Badge>
      )
    }

    if (event.is_star_chef) {
      return <StarChefIndicator tooltipPosition={tooltipPosition} />
    }

    if (event.is_best_seller) {
      return <BestSellerIndicator tooltipPosition={tooltipPosition} />
    }

    if (event.is_online_experience) return <OnlineExperienceIndicator tooltipPosition={tooltipPosition} />

    if (event.is_demo_event) {
      return <NewExperienceIndicator tooltipPosition={tooltipPosition} />
    }

    return null
  }, [
    event.is_best_seller,
    event.is_demo_event,
    event.is_online_experience,
    event.is_star_chef,
    event.masterchef_us,
    host.is_eco_hero,
    t,
  ])

  function onEventClick(e) {
    e.stopPropagation() // Prevent link click event to bubble up to container's onClick
    gtmEventClicked({ type: gtmType, event, place: eventPlace, position })
  }

  return (
    <EventCard
      forwardedAs={SmartLink}
      display='block'
      href={eventPathDescriptor}
      onClick={onEventClick}
      target={isTabletOrHigher ? '_blank' : null}
      isSelected={isSelected}
      isHovered={isHovered}
      css={`
        border: ${({ theme, isSelected, isHovered }) =>
          isSelected || isHovered ? `${theme.borders.sm} ${theme.colors.eatwithOrange}` : undefined};
        box-shadow: ${isSelected || isHovered ? '0 0 18px 0 rgba(0, 0, 0, 0.15)' : undefined};

        ${({ theme, isSelected }) => theme.media.tablet`
          transform: ${isSelected ? 'scale(1.05)' : 0};
          transition: transform 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
        `};
      `}
    >
      {!isSmall && (
        <EventCard.Header
          eventIndicator={eventIndicator}
          date={!hideNextAvailableDate && seatsLeft ? nextDate : undefined}
          {...(!hideSeatsLeft && seatsLeft)}
        />
      )}

      <EventCard.Body
        showCarousel={showCarousel}
        height={isSmall && !height ? '143px' : height}
        images={images}
        direction={direction}
      >
        <EventCard.Content
          eventCategory={translatedMealtype}
          eventTitle={isSmall ? null : getEventTitle(locale, event)}
          avatarImage={isSmall ? null : scaleCropFile({ fileId: host.avatar_id, size: '65x65', smart: true })}
          avatarAlt={isSmall ? null : `${t('EventCard::Hosted by')} ${host.firstname}`}
          bottom={isSmall ? '8px' : null}
        />
      </EventCard.Body>

      <EventCard.Footer
        rating={<ReviewRating user={host} />}
        formattedPrice={formattedPrice}
        priceText={isSmall ? null : `/${t('EventPage::FormCard::guest')}`}
        gap={isSmall ? '8px' : null}
        py={isSmall ? '4px' : null}
        height={isSmall ? '78px' : null}
      >
        {isSmall && <EventCard.Description fontWeight='bolder'>{getEventTitle(locale, event)}</EventCard.Description>}
      </EventCard.Footer>
    </EventCard>
  )
})

BasicSearchEventCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string,
    cover_id: PropTypes.number,
    masterchef_us: PropTypes.number,
    is_star_chef: PropTypes.bool,
    is_best_seller: PropTypes.bool,
    is_online_experience: PropTypes.bool,
    is_demo_event: PropTypes.bool,
    next_date: PropTypes.immutable.map,
    price: PropTypes.number,
    place: PropTypes.shape({}),
    files_ids: PropTypes.arrayOf(PropTypes.number),
    user: PropTypes.shape({
      firstname: PropTypes.string,
      is_eco_hero: PropTypes.bool,
      avatar_id: PropTypes.number,
    }),
  }).isRequired,
  eventUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  hideSeatsLeft: PropTypes.bool,
  hideNextAvailableDate: PropTypes.bool,
  gtmType: PropTypes.string,
  showCarousel: PropTypes.bool,
  place: PropTypes.shape({}),
  isSelected: PropTypes.bool,
  isHovered: PropTypes.bool,
  variant: PropTypes.oneOf(['large', 'small']),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  position: PropTypes.number,
}

BasicSearchEventCard.defaultProps = {
  hideSeatsLeft: false,
  hideNextAvailableDate: false,
  eventUrl: undefined,
  gtmType: undefined,
  showCarousel: true,
  isSelected: false,
  isHovered: false,
  height: undefined,
  place: undefined,
  variant: 'large',
  position: undefined,
}
